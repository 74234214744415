////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(243, 239, 232, 1);
}
.bg-secondaryColor {
  background-color: rgba(232, 218, 196, 1);
}
.bg-thirdColor {
  background-color: rgba(111, 82, 7, 1);
}
.bg-fourthColor {
  background-color: rgba(6, 84, 45, 1);
}
.bg-fifthColor {
  background-color: rgba(167, 114, 8, 1);
}
.bg-sixthColor {
  background-color: rgba(243, 239, 232, 1);
}
.bg-seventhColor {
  background-color: rgba(243, 239, 232, 1);
}
